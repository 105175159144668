<template>
  <div>
    <span class="hidden">{{ selectedWells }}</span>

    <!-- <CorrelationBar class="mb-4 fixed z-40 w-full" /> -->
    <ul class="sidebar" :class="side_menu">
      <div class="sidebar_nav_section" style="height: fit-content">
        <div>
          <!--   This section should be at the top -->
          <div class="section_nav_section">
            <ul v-if="true" class="left_mnemo h-full coreMenu">
              <li>
                <button
                  v-on:click="toggle_height_handler()"
                  :class="[
                    'dropdownToggleBtn',
                    'text-left',
                    'w-full',

                    'td_btn',
                    'flex',
                    darkDark !== 'white' ? 'td_btn_dark' : 'drop_btn_light',
                  ]"
                >
                  MAP
                  <span v-if="!open_map"
                    ><i class="fas fa-angle-down"></i
                  ></span>
                  <span v-else><i class="fas fa-angle-up"></i></span>
                </button>
                <ul
                  v-if="open_map"
                  v-bind:style="{
                    height: open_map ? '' : '0px',
                  }"
                  class="text-xs"
                  :class="[darkDark !== 'white' ? 'drop_backg' : '']"
                >
                  <label
                    :class="['text-left', 'w-full', 'td_btn', 'flex', 'mt-2']"
                  >
                    <div class="flex w-full">
                      MAP
                      <input
                        class="hidden"
                        type="checkbox"
                        name="map"
                        @click="openMap"
                      />
                    </div>
                    <div
                      class="map_icon"
                      :class="mapChecked ? 'mapActive' : 'mapInactive'"
                    >
                      <i class="fas fa-solid fa-crosshairs"></i>
                    </div>
                  </label>
                </ul>
              </li>
              <li>
                <button
                  v-on:click="toggle_handler_wellList()"
                  :class="[
                    'dropdownToggleBtn',
                    'text-left',
                    'w-full',

                    'td_btn',
                    'flex',
                    darkDark !== 'white' ? 'td_btn_dark' : 'drop_btn_light',
                  ]"
                >
                  WELL LIST
                  <span v-if="open_wellList"
                    ><i class="fas fa-angle-down"></i
                  ></span>
                  <span v-else><i class="fas fa-angle-up"></i></span>
                </button>
                <ul
                  v-if="open_wellList"
                  v-bind:style="{
                    height: open_wellList ? '' : '0px',
                  }"
                  class="text-xs"
                  :class="[darkDark !== 'white' ? 'drop_backg' : '']"
                >
                  <li
                    :key="index + widgetId"
                    v-for="(i, index) of selectedWells"
                  >
                    <label
                      :class="['text-left', 'w-full', 'td_btn', 'flex', 'mt-2']"
                    >
                      <div class="flex w-full">
                        {{ i.name }}
                        <input
                          :id="widgetId + index"
                          v-on:click="
                            selectWellToRelate(
                              i,
                              $event.target.checked,
                              $event,
                              index
                            )
                          "
                          :checked="isSelected(i)"
                          type="checkbox"
                          :name="widgetId + 'bha'"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
              <li>
                <button
                  v-on:click="toggle_optimization_handler()"
                  :class="[
                    'dropdownToggleBtn',
                    'text-left',
                    'w-full',

                    'td_btn',
                    'flex',
                    darkDark !== 'white' ? 'td_btn_dark' : 'drop_btn_light',
                  ]"
                >
                  Optimization
                  <span v-if="open_Optmization"
                    ><i class="fas fa-angle-down"></i
                  ></span>
                  <span v-else><i class="fas fa-angle-up"></i></span>
                </button>
                <ul
                  v-if="open_Optmization"
                  v-bind:style="{
                    height: open_Optmization ? '' : '0px',
                  }"
                  class="text-xs"
                  :class="[darkDark !== 'white' ? 'drop_backg' : '']"
                >
                  <li :key="index" v-for="(i, index) of optimizations">
                    <label
                      :class="[
                        'text-left',
                        'w-full',
                        'rounded',
                        'td_btn',
                        'flex',
                        'mt-2',
                      ]"
                    >
                      <div class="flex w-full items-center">
                        {{ i.optimization_type }}
                        <input
                          v-on:click="
                            selectOptimization(i, $event.target.checked)
                          "
                          :checked="isSelectedOpt(i)"
                          type="radio"
                          name="optimization"
                          class="checkbox_optimization"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
              <li>
                <button
                  v-on:click="toggle_section_handler()"
                  :class="[
                    'dropdownToggleBtn',
                    'text-left',
                    'w-full',

                    'td_btn',
                    'flex',
                    darkDark !== 'white' ? 'td_btn_dark' : 'drop_btn_light',
                  ]"
                >
                  Section
                  <span v-if="open_Section"
                    ><i class="fas fa-angle-down"></i
                  ></span>
                  <span v-else><i class="fas fa-angle-up"></i></span>
                </button>
                <ul
                  v-if="open_Section"
                  v-bind:style="{
                    height: open_Section ? '' : '0px',
                  }"
                  class="text-xs"
                  :class="[darkDark !== 'white' ? 'drop_backg' : '']"
                >
                  <li
                    :key="index + 'section'"
                    v-for="(i, index) of Object.keys(this.wellSections)"
                  >
                    <label
                      :class="[
                        'font-bold',
                        'text-left',
                        'w-full',
                        'rounded',
                        'td_btn',
                        'flex',
                        'mt-2',
                      ]"
                    >
                      <div class="flex w-full">
                        {{ i }}
                        <input
                          v-on:click="
                            select_section_handler(this.wellSections[i])
                          "
                          :checked="isSelectSection(i)"
                          type="radio"
                          name="section"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
              <!-- <li>
              <button v-on:click="toggle_dayvsdepth_handler()" :class="[
                'font-bold',
                'text-left',
                'w-11/12',
                'rounded',
                'td_btn',
                'flex',
                darkDark  !== 'white'   ? 'td_btn_dark' : 'drop_btn_light',
              ]">
                Days vs Depth
                <span v-if="open_dayvsdepth"><i class="fas fa-angle-down"></i></span>
                <span v-else><i class="fas fa-angle-up"></i></span>
              </button>
              <ul v-if="open_dayvsdepth" v-bind:style="{
                height: open_dayvsdepth ? '' : '0px',
              }" class="text-xs px-2" :class="[darkDark  !== 'white'   ? 'drop_backg' : '']">
                <li>
                  <label :class="[
                    'font-bold',
                    'text-left',
                    'w-full',
                    'rounded',
                    'td_btn',
                    'flex',
                    'mt-2',
                  ]">
                    <div class="flex w-full">
                      Days vs Depth
                      <input v-on:click="this.daysVsDepthToggle($event)" type="checkbox" name="bha" />
                    </div>
                  </label>
                </li>
              </ul>
            </li> -->
            </ul>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import api from "../../api/services";

export default {
  name: "correlation-plot",
  data() {
    return {
      open_map: true,
      open_wellList: false,
      open_Optmization: false,
      open_Section: false,
      open_dayvsdepth: false,
      side_menu: true,
      mapChecked: true,
      selectedWells: [],
      optimizations: [],
      selectedWellsName: [],
      wellSections: {},
      selectedOptimization: null,
    };
  },
  props: {
    correlationIdx: [String, Number],
    displayId: String,
    widgetId: String,
    widgetIndex: [Number, String],
    displayIndex: String,
  },
  mounted() {
    this.wellSections = {};
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "rect/setCorrelationSelectedWell":
          let widget = mutation.payload.value;
          this.selectedWells = { ...mutation.payload.value };

          if (widget) {
            for (let i in widget) {
              if (!widget[i].lat) {
                delete this.selectedWells[i];
              } else {
                this.selectedWellsName.push(widget[i].name);
              }
            }
          }

          break;
      }
    });
    this.optimizations = this.$store.state.data.optimizations;
  },
  methods: {
    daysVsDepthToggle(event) {
      this.$store.dispatch("disp/daysVsDepthToggle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        widgetIndex: this.widgetIndex,
        displayIndex: this.displayIndex,
        value: event.target.checked,
      });
    },
    select_section_handler(section) {
      this.$store.dispatch("rect/wellSection", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        widgetIndex: this.widgetIndex,
        value: section,
      });
    },
    getIndex(state, widgetId, displayId) {
      let index = -1;
      for (let i = 0; i < state.rects.length; i++) {
        if (
          state.rects[i].widgetId == widgetId &&
          state.rects[i].displayId == displayId
        ) {
          index = i;
          return index;
        }
      }
    },
    isSelected(well) {
      // let isChecked = false;
      // if (this.$store.state.rect.rects[this.widgetIndex].wellsToRelate)
      //   for (let i of this.$store.state.rect.rects[this.widgetIndex]
      //     .wellsToRelate) {
      //     if (well.name == i.name) {
      //       isChecked = true;

      //       break;
      //     }
      //   }
      // console.log('checking rects::::::in store wellsToRelate:::is selected2',this.widgetIndex,this.$store.state.rect.rects)
      // return isChecked;
      let idx = this.getIndex(
        this.$store.state.rect,
        this.widgetId,
        this.displayId
      );
      let isChecked = false;
      if (this.$store.state.rect.rects[idx].wellsToRelate)
        for (let i of this.$store.state.rect.rects[idx].wellsToRelate) {
          if (well.name == i.name) {
            isChecked = true;

            break;
          }
        }
      console.log(
        "checking rects::::::in store wellsToRelate:::is selected2",
        idx,
        this.$store.state.rect.rects
      );
      return isChecked;
    },
    isSelectSection(section) {
      let isChecked = false;
      if (
        this.$store.state.rect.rects[this.widgetIndex]?.wellSection &&
        this.$store.state.rect.rects[this.widgetIndex].wellSection[0]
      )
        if (
          this.$store.state.rect.rects[this.widgetIndex].wellSection[0]
            .bit_size == section
        ) {
          isChecked = true;
        }
      return isChecked;
    },
    isSelectedOpt(optimization) {
      let isChecked = false;
      console.log(
        " is selected option this.widget index : ",
        this.widgetIndex,
        this.$store.state.rect.rects[this.widgetIndex]
      );
      if (
        this.$store.state.rect.rects[this.widgetIndex]?.wellRelationOptimization
          ?.optimization_type == optimization.optimization_type
      ) {
        isChecked = true;
      }

      return isChecked;
    },
    openMap() {
      this.mapChecked = !this.mapChecked;
      this.$store.dispatch("disp/correlationMap", {
        display: this.displayId,
      });
    },
    toggle_height_handler() {
      this.open_map = !this.open_map;
    },
    toggle_handler_wellList() {
      this.open_wellList = !this.open_wellList;
    },
    toggle_optimization_handler() {
      this.open_Optmization = !this.open_Optmization;
    },
    async toggle_section_handler() {
      if (!this.open_Section) {
        let sourceids = [];
        for (let i of this.$store.state.rect.rects[this.widgetIndex]
          .wellsToRelate) {
          for (let j in i.wellbores) {
            sourceids.push(i.wellbores[j]["logs"]["depth"].id);
          }
        }
        if (!sourceids.length) {
          this.$toast.info("Select Wells First ", {
            position: "top",
            duration: "1000",
          });
          return false;
        }
        let wellSectionRes = await this.getWellSections({
          sourceids: sourceids,
        });
        let tempSections = {};
        for (let section of wellSectionRes) {
          if (tempSections[section.bit_size]) {
            tempSections[section.bit_size].push(section);
          } else {
            tempSections[section.bit_size] = [section];
          }
        }

        for (let i in tempSections) {
          if (tempSections[i].length != sourceids.length) {
            delete tempSections[i];
          }
        }

        this.wellSections = tempSections;
      }
      this.open_Section = !this.open_Section;
    },
    async getWellSections(data) {
      try {
        const wellSections = await api.WellServices.getWellSections(data);

        return wellSections.data;
      } catch (error) {
        return [];
      }
    },
    toggle_dayvsdepth_handler() {
      this.open_dayvsdepth = !this.open_dayvsdepth;
    },
    selectWellToRelate(data, checked, e, index) {
      if (
        this.$store.state.rect.rects[this.correlationIdx].wellsToRelate
          ?.length == 3 &&
        checked
      ) {
        this.$toast.info("Maximum 3 Wells ", {
          position: "top",
          duration: "1000",
        });
        e.target.checked = false;
        return false;
      }

      if (
        this.$store.state.rect.rects[this.correlationIdx].wellsToRelate
          ?.length == 2 &&
        !checked
      ) {
        this.$toast.info("Minimum 2 Well ", {
          position: "top",
          duration: "1000",
        });
        e.target.checked = true;
        return false;
      }
      this.$store.dispatch("rect/wellsToRelate", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        widgetIndex: this.widgetIndex,
        value: data,
        checked: checked,
        index,
      });
      if (this.selectedOptimization) {
        setTimeout(() => {
          console.log(
            "selected optimization in well select::",
            this.selectedOptimization
          );
          this.$store.dispatch("rect/wellRelationOptimization", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            widgetIndex: this.widgetIndex,
            value: this.selectedOptimization,
            checked: checked,
          });
        }, 500);
      }
      setTimeout(() => {
        this.$store.dispatch("rect/selectFeet", {
          widgetId: this.widgetId,
          displayId: this.displayId,
          widgetIndex: this.widgetIndex,
          // trendIndex: this.trendIndex,
          value: 500,
        });
        this.$store.dispatch("rect/toolZoomToggle", {
          isZoom: false,
          widgetId: this.widgetId,
          displayId: this.displayId,
          widgetIndex: this.widgetIndex,
          // trendIndex: this.trendIndex,
        });
      }, 500);
    },
    selectOptimization(data, checked) {
      this.selectedOptimization = data;
      this.$store.dispatch("rect/wellRelationOptimization", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        widgetIndex: this.widgetIndex,
        value: data,
        checked: checked,
      });
    },
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    // selectedWells(){
    //   let result = []
    //
    //   let widget = this.$store.state.rect[this.widgetIndex]?.correlationSelectedWells
    //   for (let i in widget) {
    //           if (widget[i].lat) {
    //             result.push({ ...widget[i] })
    //           }
    //         }
    //   return result
    // }
  },
};
</script>
<style scopped>
.checkbox_optimization:checked {
  background-color: black !important;
}
</style>
